const SylvaniaPatioPrivateEventsImagesData = [
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/sylvania-patio-private-events-image-1.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/sylvania-patio-private-events-image-1.webp',
  },
];

export default SylvaniaPatioPrivateEventsImagesData;
