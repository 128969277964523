const TheGardenPrivateEventsRoomData = [
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/the-garden-patio-private-events-image-1.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/the-garden-patio-private-events-image-2.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/the-garden-patio-private-events-image-3.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/the-garden-patio-private-events-image-4.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/the-garden-patio-private-events-image-5.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/the-garden-patio-private-events-image-6.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/the-garden-patio-private-events-image-7.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/the-garden-patio-private-events-image-8.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/the-garden-patio-private-events-image-9.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/private-events/the-garden-patio-private-events-image-10.webp',
  },
];

export default TheGardenPrivateEventsRoomData;
