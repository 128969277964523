const GlassCityPavilionPrivateEventsImagesData = [
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/glass-city-pavilion/glass-city-pavilion-events-image-1.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/glass-city-pavilion/glass-city-pavilion-events-image-2.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/glass-city-pavilion/glass-city-pavilion-events-image-3.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/glass-city-pavilion/glass-city-pavilion-events-image-4.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/glass-city-pavilion/glass-city-pavilion-events-image-5.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/glass-city-pavilion/glass-city-pavilion-events-image-6.webp',
  },
  {
    image:
      'https://pocopiatti.s3.us-east-2.amazonaws.com/glass-city-pavilion/glass-city-pavilion-events-image-7.webp',
  },
];

export default GlassCityPavilionPrivateEventsImagesData;
