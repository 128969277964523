import React, { useState } from 'react';
import styled from 'styled-components';

// Components
import CircleMinus from '../CircleMinus';
import CirclePlus from '../CirclePlus';

// Styles
import mixins from '../../styles/mixins';
import theme from '../../styles/theme';

const { space, color } = theme;

const CateringPackage = styled.div`
  margin-bottom: ${space.space};
`;

const AccordionPanelContainer = styled.div`
  padding: 1.15em 0;
  border-bottom: 1px solid ${color.callout};
  width: 100%;

  .title {
    margin-bottom: 0;
  }
`;

const AccordionPanelHead = styled.div`
  ${mixins.flexBetween};
`;

const AccordionPanelButton = styled.button`
  background: none;
  border: none;
  -webkit-appearance: none;
  padding: ${space.halfSpace};

  svg {
    height: 2.85rem;
    width: 2.85rem;
    fill: ${color.foreground};
    vertical-align: middle;
  }
`;

const AccordionPanelContent = styled.div`
  p {
    margin-bottom: ${space.halfSpace};
  }
`;

function ALaCarte() {
  const [collapsed, expanded] = useState(true);

  const handleClick = () => (collapsed ? expanded(false) : expanded(true));

  return (
    <AccordionPanelContainer>
      <AccordionPanelHead>
        <div className="title">A La Carte</div>
        <AccordionPanelButton onClick={handleClick}>
          <CirclePlus collapsed={collapsed} />
          <CircleMinus collapsed={collapsed} />
        </AccordionPanelButton>
      </AccordionPanelHead>
      <AccordionPanelContent className={collapsed ? 'collapsed' : 'expanded'}>
        <CateringPackage>
          <h5>Hummous</h5>
          <p>
            Half Pan (20 ppl.) &mdash; <span>40</span>
          </p>
          <p>
            Full Pan (40 ppl.) &mdash; <span>75</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Vegetable Tray</h5>
          <p>
            Assorted raw vegetables (20 ppl.) &mdash; <span>35</span>
          </p>
        </CateringPackage>
        {/* <CateringPackage>
          <h5>Charcuterie Board</h5>
          <p>
            3 Cheeses, 3 Meats, pickles, and jam (20 ppl.) &mdash;{' '}
            <span>75</span>
          </p>
        </CateringPackage> */}
        <CateringPackage>
          <h5>Feta Dip</h5>
          <p>
            Half Pan (20 ppl.) &mdash; <span>50</span>
          </p>
          <p>
            Full Pan (40 ppl.) &mdash; <span>95</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Fatoosh Salad</h5>
          <p>
            Half Pan (20 ppl.) &mdash; <span>40</span>
          </p>
          <p>
            Full Pan (40 ppl.)&mdash;<span>75</span>{' '}
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Greek Salad</h5>
          <p>
            Half Pan (20 ppl.) &mdash; <span>40</span>
          </p>
          <p>
            Full Pan (40 ppl.) &mdash; <span>75</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Poco House Salad</h5>
          <p>
            Half Pan (20 ppl.) &mdash; <span>40</span>
          </p>
          <p>
            Full Pan (40 ppl.) &mdash; <span>75</span>{' '}
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Artichoke Salad</h5>
          <p>
            Half Pan (20 ppl.) &mdash; <span>40</span>{' '}
          </p>
          <p>
            Full Pan (40 ppl.) &mdash; <span>75</span>{' '}
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Tabouli</h5>
          <p>
            Half Pan (20 ppl.) &mdash; <span>40</span>{' '}
          </p>
          <p>
            Full Pan (40 ppl.) &mdash; <span>75</span>{' '}
          </p>
        </CateringPackage>
        {/* <CateringPackage>
          <h5>Waldorf Salad</h5>
          <p>
            Half Pan (20 ppl.) &mdash; <span>40</span>{' '}
          </p>
          <p>
            Full Pan (40 ppl.) &mdash; <span>75</span>{' '}
          </p>
        </CateringPackage> */}
        {/* <CateringPackage>
          <h5>Chopped Salad</h5>
          <p>
            Half Pan (20 ppl.) &mdash; <span>40</span>{' '}
          </p>
          <p>
            Full Pan (40 ppl.) &mdash; <span>75</span>{' '}
          </p>
        </CateringPackage> */}
        {/* <CateringPackage>
          <h5>Caesar Salad</h5>
          <p>
            Half Pan (20 ppl.) &mdash; <span>40</span>{' '}
          </p>
          <p>
            Full Pan (40 ppl.) &mdash; <span>75</span>{' '}
          </p>
        </CateringPackage> */}
        <CateringPackage>
          <h5>Sauteed Mushrooms</h5>
          <p>
            Half Pan (20 ppl.) &mdash; <span>50</span>{' '}
          </p>
          <p>
            Full Pan (40 ppl.) &mdash; <span>90</span>{' '}
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Brussel Sprouts</h5>
          <p>
            Half Pan (20 ppl.) &mdash; <span>55</span>{' '}
          </p>
          <p>
            Full Pan (40 ppl.) &mdash; <span>100</span>{' '}
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Roasted Cauliflower</h5>
          <p>
            Half Pan (20 ppl.) &mdash; <span>55</span>{' '}
          </p>
          <p>
            Full Pan (40 ppl.) &mdash; <span>100</span>{' '}
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Mujadara</h5>
          <p>
            Half Pan (20 ppl.) &mdash; <span>40</span>{' '}
          </p>
          <p>
            Full Pan (40 ppl.) &mdash; <span>75</span>{' '}
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Poco Rice</h5>
          <p>
            Half Pan (20 ppl.) &mdash; <span>30</span>{' '}
          </p>
          <p>
            Full Pan (40 ppl.) &mdash; <span>55</span>{' '}
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Greek Potatoes</h5>
          <p>
            Half Pan (20 ppl.) &mdash; <span>40</span>{' '}
          </p>
          <p>
            Full Pan (40 ppl.) &mdash; <span>75</span>{' '}
          </p>
        </CateringPackage>
        {/* <CateringPackage>
          <h5>White Cheddar Mac & Cheese</h5>
          <p>
            Half Pan (20 ppl.) &mdash; <span>50</span>{' '}
          </p>
          <p>
            Full Pan (40 ppl.) &mdash; <span>90</span>{' '}
          </p>
        </CateringPackage> */}
        {/* <CateringPackage>
          <h5>Au Gratin Potatoes</h5>
          <p>
            Half Pan (20 ppl.) &mdash; <span>45</span>{' '}
          </p>
          <p>
            Full Pan (40 ppl.) &mdash; <span>85</span>{' '}
          </p>
        </CateringPackage> */}
        <CateringPackage>
          <h5>Shish Tawook</h5>
          <p>
            10 skewers &mdash; <span>90</span>
          </p>
          <p>
            20 skewers &mdash; <span>180</span>
          </p>
          <p>
            30 skewers &mdash; <span>270</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Beef Tenderloin Kabob</h5>
          <p>
            10 skewers &mdash; <span>110</span>
          </p>
          <p>
            20 skewers &mdash; <span>220</span>
          </p>
          <p>
            30 skewers &mdash; <span>330</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Shish Kafta</h5>
          <p>
            10 skewers &mdash; <span>100</span>
          </p>
          <p>
            20 skewers &mdash; <span>200</span>
          </p>
          <p>
            30 skewers &mdash; <span>300</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Veggie Kabob</h5>
          <p>
            10 skewers &mdash; <span>70</span>
          </p>
          <p>
            20 skewers &mdash; <span>140</span>
          </p>
          <p>
            30 skewers &mdash; <span>210</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Shrimp Asti-Spumante</h5>
          <p>
            30 pieces &mdash; <span>80</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Thai Shrimp Lasagna</h5>
          <p>
            30 pieces &mdash; <span>80</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Shrimp Saganaki</h5>
          <p>
            30 pieces &mdash; <span>80</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Lamb Chops</h5>
          <p>
            20 &mdash; <span>90</span>
          </p>
          <p>
            40 &mdash; <span>180</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Chicken Wraps</h5>
          <p>
            10 wraps &mdash; <span>40</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Ara-yes Halabi</h5>
          <p>
            24 pcs. &mdash; <span>45</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Chicken Sliders</h5>
          <p>
            10 &mdash; <span>40</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Lamb Sliders</h5>
          <p>
            10 &mdash; <span>40</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Grape Leaves</h5>
          <p>
            40 &mdash; <span>40</span>
          </p>
        </CateringPackage>
        <CateringPackage>
          <h5>Poco Tenderloin Filet (Whole)</h5>
          <p>
            20 people &mdash; <span>300</span>
          </p>
        </CateringPackage>
        {/* <CateringPackage>
          <h5>Prime Rib</h5>
          <p>20 people &mdash; 350</p>
        </CateringPackage>
        <CateringPackage>
          <h5>Roasted Pork Loin</h5>
          <p>20 people &mdash; 200</p>
        </CateringPackage> */}
        {/* <CateringPackage>
          <h5>Not Your Mama's Shake & Bake</h5>
          <p>
            10 people &mdash; <span>80</span>
          </p>
          <p>
            20 people &mdash; <span>150</span>
          </p>
          <p>
            30 people &mdash; <span>225</span>
          </p>
        </CateringPackage> */}
        <CateringPackage>
          <h5>Grilled Northern Atlantic Salmon</h5>
          <p>
            10 people &mdash; <span>125</span>
          </p>
          <p>
            20 people &mdash; <span>250</span>
          </p>
          <p>
            30 people &mdash; <span>375</span>
          </p>
        </CateringPackage>
        {/* <CateringPackage>
          <h5>Braised Mushroom Short Ribs</h5>
          <p>
            10 people &mdash; <span>125</span>
          </p>
          <p>
            20 people &mdash; <span>250</span>
          </p>
          <p>
            30 people &mdash; <span>375</span>
          </p>
        </CateringPackage> */}
        <CateringPackage>
          <h5>Berry Crisp (Warm Dessert)</h5>
          <p>
            Half Pan (10 - 15 ppl.) &mdash; <span>45</span>{' '}
          </p>
          <p>
            Full Pan (30 - 40 ppl.) &mdash; <span>90</span>{' '}
          </p>
        </CateringPackage>
        {/* <CateringPackage>
          <h5>Cardamon Fig Bread Pudding (Warm Dessert)</h5>
          <p>
            Half Pan (10 - 15 ppl.) &mdash; <span>45</span>{' '}
          </p>
          <p>
            Full Pan (30 - 40 ppl.) &mdash; <span>90</span>{' '}
          </p>
        </CateringPackage> */}
        <CateringPackage>
          <h5>Brownies & Cookies</h5>
          <p>
            Assortment &mdash; <span>4 per person</span>{' '}
          </p>
        </CateringPackage>
      </AccordionPanelContent>
    </AccordionPanelContainer>
  );
}

export default ALaCarte;
